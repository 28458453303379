@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'DM Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #000;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:48px;
	letter-spacing:0px;
	text-transform:capitalize;
	font-family: 'DM Sans', sans-serif;
	font-weight:500;
	color:#000;
}
h2 {
	font-size:22px;
	font-weight:500;
	letter-spacing:0;
	text-transform: none !important;
	line-height:20px;
	font-family: 'DM Sans', sans-serif;
}
h3 {
	font-size:16px;
	font-weight:500;
	line-height:inherit;
	font-family: 'DM Sans', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'DM Sans', sans-serif;
}
h6 {
	font-size:30px;
	letter-spacing:0px;
	text-transform:inherit;
	font-family: 'DM Sans', sans-serif;
    font-weight: 600;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'DM Sans', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#000;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 14px;
	color: #fff;
	font-family: 'DM Sans', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#1E5D9E;
	box-shadow:none;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #fff !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'DM Sans', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #000;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 8px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #000;
	font-size: 14px;
	font-family: 'DM Sans', sans-serif;
}
.links > li > a:hover, .userSwitch a:hover {
	background-color: #1E5D9E;;
	color: #ffffff !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #000;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #000;
}
#nav .nav-panel--dropdown a {
	color: #000;
	font-size: 14px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 14px;
}
#nav .nav-panel--dropdown a:hover {
	color: #000;
}
.mobnav-trigger.active {
	background-color: #000;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #000;
}
#nav .nav-item--home .fa-home {
	color: #000 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #000;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #000;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #000;
	border-right-color: #000;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #1E5D9E !important;
	padding: 0;
	border-radius: 30px;
	max-height: 45px;
	font-size: 16px;
	margin-top: 10px;
}
#mini-cart .actions button.button span span:hover {
	background-color: #1E5D9E !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 12px;
	letter-spacing: 0px;
	text-transform: capitalize;
    color:#000;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
        height: 24px;
    line-height: 24px;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 48px;
	color: #000;
	letter-spacing: 0px;
	text-transform: capitalize;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #1E5D9E;
	border-radius: 30px;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'DM Sans', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}
button.button span span {
	padding: 0 24px;
    height:40px;
    line-height:40px;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #777 !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #000;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #fff;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #ddd;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #ebebeb;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #fff;
	color: #999;
    display:none;
}


/************************** MICS **************************/

/*Header*/
.desktop-top-header .header-top-container, .mobile-header .header-top-container{
     background-color:#fff;
}
.top-header-container .module-header-multi span, .top-header-container .module-header-multi a{
     height:32px;
     line-height:32px;
}
.top-header-container .module-header-multi p{
     margin-bottom:0 !important;
     height:32px;
     line-height:32px;
}
/*user links*/
.top-header-container .links > li > a {
     color:#fff;
     padding:0 1.2em;
}
 .top-header-container ul.links li:nth-child(3) {
     display:none;
}
.desktop-top-header {
     padding:10px 0 30px 0 !important;
     
}
.std i {
     font-style:normal;
}
/*.desktop-top-header .grid-container {
     display:flex;
}*/
.desktop-top-header .grid-container > div {
     margin:auto;
}
.desktop-top-header .cart-column {
     width:auto;
}
/*Contact link*/
.contact-account-link .page-content > div > a:nth-child(1) img {
     margin-top:2px;
}
.contact-account-link .page-content > div {
     display:flex;
     justify-content: space-around;
}
@media only screen and (max-width:1199px) {
     .userSwitch a {
         text-indent:-9999px;
         position:absolute;
    }
     .userSwitch a::after {
         content:"\f0c0";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:14px;
         text-indent:0;
         float:left;
    }
}
/*Logo*/
.logo-column a:hover svg path{
     fill:#FF6955 !important;
}
@media only screen and (min-width:1260px) and (max-width:1799px) {
     .desktop-top-header .logo-column {
         width:21.66%;
    }
}
@media only screen and (min-width:1800px) {
     .desktop-top-header .logo-column {
         width:23.66%;
    }
}
.desktop-top-header .logo-column svg {
     width:50%;
     height:auto;
}
@media only screen and (max-width:1259px) {
     .desktop-top-header .logo-column svg {
         width:80%;
    }
}
@media only screen and (max-width:959px) {
     .desktop-top-header .logo-column svg, .mobile-logo-img svg {
         width:100%;
         height:auto;
    }
}
/*category*/
.category-col .module-category-menu .nav-item.nav-item--home {
     display:none;
}
/*.category-col ul#nav {
     display:flex;
     justify-content:space-around;
}*/
.category-col .nav-regular li.level0>a>span {
     font-weight:500;
     text-transform:Capitalize;
     color:#000;
         font-size: 0.78vw;
    line-height: 1.1vw;
}
@media only screen and (max-width:1799px) {
  .category-col .nav-regular li.level0>a>span {
  font-size:14px !important;
     line-height:20px !important;
}
}
.category-col .nav-regular li.level0 {
    margin-right: 1.3vw;
}
.category-col .nav-regular li.level0>a {
    padding: 0;
    padding-right: 0.61vw;
}
.category-col .nav-regular li.level0>a>span {
     padding-right:0.61vw;
}

/*@media only screen and (min-width:1400px) {
     .category-col .nav-regular li.level0>a>span {
         font-size:16px;
    }
}
@media only screen and (min-width:1600px) {
     .category-col .nav-regular li.level0>a>span {
         font-size:18px;
    }
}*/
.category-col .nav-regular .nav-panel li.classic {
     margin-bottom:5px;
}
/*Change caret to chevron*/
/*
#nav .nav-item.level0.parent>a .caret {
     border:none;
     vertical-align:baseline;
     opacity:.7;
}
#nav .nav-item.level0.parent>a .caret::before {
     content:"\f078";
     font-family:fontAwesome;
     text-indent:0;
     float:left;
     font-size:12px;
}
*/
/*Search*/
.desktop-top-header .search-column .item.item-search{
     width:70%;
     float:right;
}
@media only screen and (min-width:1600px) {
     .desktop-top-header .search-column .item.item-search{
         width:60%;
    }
}
.module-search .form-search input::placeholder {
     color:#D7DBDD !important;
}
.module-search .form-search .button-search {
     top:3px !important;
     left:5px !important;
     width: 20px;
    height: 20px;
}
.desktop-top-header .module-search .form-search .button-search .fa {
     color:#fff !important;
     font-weight:100;
     background-color:#1E5D9E;
     border-radius:50px;
     padding:4px !important;
}
.desktop-top-header .module-search .form-search .input-text {
     border-radius:30px;
     font-weight:400;
     font-size:14px;
     border:1px solid #D7DBDD;
     padding:0 7px 0 38px;
    color: #4d4e50;
    height: 30px;
    line-height: 30px;
}
.desktop-top-header .search-column, .desktop-top-header .contact-account-link, .desktop-top-header .cart-column {
     margin-top:10px !important;
}
.desktop-top-header .cart-column {
     margin-left:7px;
}
.desktop-top-header .search-column .item.item-search {
     margin-right:6%;
}
#mini-cart .feature-icon-hover:hover .cart-total {
     background-color:#FF6955;
}
 .contact-account-link svg path {
     fill:#000;
}
.contact-account-link a:hover svg path, #mini-cart .feature-icon-hover:hover svg path {
     fill:#FF6955;
}
/*Cart*/
.desktop-top-header .cart-column .fa.fa-shopping-cart, .mobile-header .fa.fa-shopping-cart{
     display:none !important;
}
.feature-icon-hover .caret {
     border-top-color:#FF6955;
}
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
@media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
@media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}
#inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         width:900px;
         right:0;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68.5%;
    }
     #inline-search-results li.grow {
         width:45%;
    }
}
@media only screen and (max-width:767px) {
     #inline-search-results {
         width:96%;
         left:0 !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     #inline-search-results {
         width:400px;
         right:0 !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
    }
}
/*Mega menu*/
.category-col ul#nav {
     margin-left:2%;
}
.nav-regular .mega > .nav-panel--dropdown{
     border-top:none !important;
     width:100vw!important;
}
.nav-regular .mega > .nav-panel--dropdown .nav-panel-inner .module-sub-categories > .nav-item {
     display:grid;
     margin-left:0;
     margin-right:0;
     grid-template-columns:35px 85%;
     
}
.nav-regular .mega > .nav-panel--dropdown .nav-panel-inner .module-sub-categories > .nav-item.grid12-3 {
    width:25%;
}
.nav-regular .mega > .nav-panel--dropdown .nav-panel-inner .module-sub-categories > .nav-item.grid12-5col {
    width:20%;
}
.nav-regular .mega > .nav-panel--dropdown .nav-panel-inner .module-sub-categories > .nav-item.grid12-2 {
    width:16.66%;
}
.nav-regular .mega > .nav-panel--dropdown .nav-panel-inner .module-sub-categories > .nav-item > ul {
     grid-column:span 2;
     border-top:1px solid #000;
     padding-top:10px;
    margin-top: 0.72vw;
    transition:all .8s ease;
}
.nav-regular .mega > .nav-panel--dropdown .nav-panel-inner .module-sub-categories .module-sub-categories-img {
     margin:auto;
}
.nav-regular .mega > .nav-panel--dropdown .nav-panel-inner .module-sub-categories .module-sub-categories-img img{
     width:24px;
}
.nav-regular .mega > .nav-panel--dropdown .nav-panel-inner .module-sub-categories > .nav-item > ul li {
     background:none;
     padding-left:10px;
}
.nav-regular .mega > .nav-panel--dropdown .nav-panel-inner .module-sub-categories div.nav-item>.module-sub-categories-title {
     padding:0 !important;
}
#nav .nav-item.level0.parent>a .caret {
     display:none;
}
#nav .nav-item.level0.parent>a > span:nth-child(1)::after {
     content:'';
     background:url('https://cdn-881a96c5-a77b871b.commercebuild.com/ba4eb150ac04bbf07de87971e67be5f8/contents/ckfinder/images/down-arrow.png');
     background-size:contain;
     width:7px;
     height:4px;
     display:block;
     position:absolute;
     right:0;
     top:50%;
     transform:translateY(-50%);
     filter:invert(0);
     transition:filter .4s ease, transform .3s ease;
     width:0.39vw;
     height:0.22vw;
}
#nav .nav-item.level0.parent>a:hover span {
     color:#FF6955 !important;
     transition:color .2s ease !important;
}
#nav .nav-item.level0.parent>a:hover > span:nth-child(1)::after {
     filter:invert(49%) sepia(32%) saturate(2392%) hue-rotate(329deg) brightness(108%) contrast(101%);
     transform:rotate(180deg);
     top:35%;
}
.nav-regular .nav-panel--dropdown.nav-panel.tmp-full-width {
     transition:all .6s ease;
     box-shadow:none;
     /*padding:2vw 2.2vw;*/
     transition:top 0.5s cubic-bezier(0.47, 0, 0.75, 0.72) 5s;
     border-bottom:1px solid #f5f5f5;
}
#nav .nav-panel-inner {
     padding:30px 0 30px 50px;
}
#nav .nav-panel-inner > div > div.grid12-12 {
     width:100%;
     margin:0;
}
#nav .nav-panel--dropdown a.module-sub-categories-title >span {
     font-size:18px;
     line-height:22px;
     font-weight:400;
     text-transform:capitalize;
}
#nav .nav-panel--dropdown li.page-item a.module-sub-categories-title span {
     font-size:16px;
     line-height:28px;
     font-weight:400;
     color:#626467;
     transition:all .7ss ease;
}
#nav .nav-panel--dropdown li.page-item a.module-sub-categories-title:hover span {
     color:#FF6955;
}
#nav .nav-panel--dropdown .module-sub-categories .nav-item.page-item:hover > .module-sub-categories-img img {
     filter:invert(50%) sepia(98%) saturate(1786%) hue-rotate(327deg) brightness(103%) contrast(109%);
}
#nav .nav-panel--dropdown .module-sub-categories .nav-item.page-item:hover > a.module-sub-categories-title span{
     color:#FF6955;
}
#nav .nav-panel--dropdown .module-sub-categories .nav-item.page-item.parent:hover > ul {
     border-color:#FF6955;
}

@media only screen and (min-width:1260px) {
     .desktop-top-header .contact-account-link {
         width:5%;
    }
}
@media only screen and (min-width:960px) and (max-width:1199px) {
     .desktop-top-header .category-col{
         width:39.67% 
    }
     .desktop-top-header .logo-column{
         width:17.66% 
    }
    .desktop-top-header .nav-regular li.level0>a {
         padding:0 6px;
    }
}
@media only screen and (max-width:959px) {
     .desktop-top-header, .cart-column, .cart-column .grid-column-wrapper, .cart-column .module-header-multi {
         overflow:unset;
    }
     .desktop-top-header {
        padding: 10px 0 30px 0 !important;
    }
     .menu-icon.fa.fa-vcard {
         color:#fff;
    }
     .desktop-top-header .category-col {
         display:none;
    }
     .desktop-top-header .search-column {
         width:39.67%;
         margin-top:auto !important;
    }
     .desktop-top-header .contact-account-link {
         width:14.66%;
    }
    .desktop-top-header .grid-container {
         display:flex;
    }
     .contact-account-link .page-content > div {
         justify-content:space-between;
    }
}
@media only screen and (max-width:767px) {
     .top-header-container, .mobile-header {
         margin-bottom:5px !important;
    }
     .top-header-container .module-header-multi > div:last-child {
         float:right;
    }
    .mobile-header {
         padding-bottom:30px;
    }
     .mobile-header .grid-container {
         display:flex;
         overflow:hidden !important;
    }
     .header .userCode, .header .userName, .header .userSwitch {
         font-size:14px;
    }
     .mobile-logo-img img {
         padding-top:2px;
    }
     .mobile-header .grid-full.module-header-multi {
         display:flex;
         justify-content:flex-start;
    }
       
    .mobile-header .grid-column-wrapper.last .grid-full.module-header-multi {
         justify-content:right;
    }
     .mobile-header .fa.fa-bars, .mobile-header .fa.fa-vcard:before, .mobile-header .item-search:not(.fly-out) .fa.fa-search {
         color:#4D4E50 !important;
         font-size:20px !important;
         font-weight:100;
    }
     .mobile-header .feature-icon-hover, .mobile-header .item-search:not(.fly-out) .fa.fa-search {
         padding-top:7px !important;
    }
    .mobile-header .fa.fa-bars {
        padding-left: 0 !important;
    }
     .mobile-header #mini-cart .empty {
         display:none;
    }
     /*Search on mobile*/
     .mobile-header div.form-search {
         margin:0 auto;
    }
     .mobile-header .module-search .form-search .input-text {
         font-size:16px !important;
    }
     /*Search*/
     .mobile-header .module-search .form-search .input-text {
         display:none;
    }
     .mobile-header .module-search .button.button-search {
         position:unset!important;
    }
     .mobile-header .item-search.fly-out {
         position:fixed;
         display:block!important;
         top:0;
         right:0;
         left:0;
         bottom:0;
         z-index:9999;
         width:100%!important;
         background-color:#fff;
         margin-left:0;
    }
     .mobile-header .item-search.fly-out .form-search .button {
         right:10px;
         position:absolute!important;
         margin:0;
         padding:0;
         font-size:inherit;
    }
     .mobile-header .item-search.fly-out .form-search .button-search {
         right:45px;
    }
     .mobile-header .form-search .button-close .fa {
         font-size:16px;
         color:#000;
         padding:10px;
         font-style:normal;
    }
}
.cart-total {
    display: inline-block;
    border-radius: 50%;
    color: #fff !important;
    background-color: #4D4E50;
    font-weight: 400;
    font-size: 12px !important;
    height: 16px;
    width: 16px;
    line-height: 16px;
    text-align: center;
    position: relative;
    top: -20px;
    left: 5px;
}
@media only screen and (max-width:767px) {
     .cart-total {
         top:-18px;
    }
}
/*Buttons for the site*/
button.button span span {
     display:flex;
     justify-content:center;
}
button.button span span img{
     width:10px;
     margin:auto 5px;
}
/*footer*/
@media only screen and (min-width:768px) {
     .footer-top-container .stmt, .footer-primary-container .help-stmt, .addrs-outer, .footer-primary-container .more-links {
         display:flex;
         justify-content:space-between;
    }
     .footer-top-container .stmt > *, .addrs-outer > div {
         margin:auto 0;
    }
}
.footer-top-container .footer-top {
     background-color:#F1F2F5 ;
     width:90%;
     padding:30px 40px;
     border-radius:30px;
}
.tch-btn {
     padding:5px 10px;
     background-color:#fff;
     border:1px solid #000;
     border-radius:30px;
     margin:auto 0;
}
.tch-btn a {
     display:flex;
     justify-content:center;
}
.tch-btn img {
     width:10px;
     margin:auto 5px;
}
.footer-primary.footer.container {
     background-color:transparent;
}
.footer-primary-container {
     border-top-left-radius:30px;
     border-top-right-radius:30px;
     margin-top:4%;
     background:url("https://cdn-881a96c5-a77b871b.commercebuild.com/ba4eb150ac04bbf07de87971e67be5f8/contents/ckfinder/images/footer-aqua-icon.jpeg") no-repeat;
    background-size: cover;
}
.footer-primary-container p, .footer-primary-container a, .footer-primary-container span, .footer-primary-container div {
     color:#fff;
}
#footer a:hover {
     color:#fff;
}
.footer-primary-container .help-stmt .scnd-col{
     width:25%;
}
.footer-primary-container .help-stmt .scnd-col > div{
     display:flex;
     justify-content:space-between;
     padding-bottom:7px;
     border-bottom:2px solid #fff;
     margin-bottom:20px;
}
.footer-primary-container .help-stmt .scnd-col > div> span{
     font-size:24px;
     font-weight:600;
}
.footer-primary-container .help-stmt .scnd-col > div > span img{
     width:30px;
}
.addrs-outer .fa, .addrs-outer .fa-brands {
     font-size:22px;
}
.addrs-outer .socio a {
     margin-right:10px;
}
.addrs-outer .socio {
     width:20%;
}
.addrs-outer {
     padding-bottom:3%;
    padding-top:2%;
     border-bottom:1px solid #fff;
}
.footer-primary-container .more-links {
     padding-top:2%;
}
.footer-primary-container .more-links .cat-links > a:nth-child(2) {
     margin:0 5px 0 10px;
}
.footer-primary-container .more-links .cat-links > a:nth-child(4) {
     margin:0 10px 0 5px;
}
.footer-primary-container .more-links .cat-links > a {
     font-weight:700;
}
.footer-primary-container .more-links .misc-links > a:nth-child(1) {
     margin-right:10px;
}
#scroll-to-top {
     display:none !important;
}
@media only screen and (max-width:1299px) {
     .footer-primary-container .help-stmt .scnd-col > div> span{
         font-size:20px;
    }
     h6 {
         font-size:26px;
         line-height:30px;
    }
    .aqua-icon img {
      width:25px;
    }
}
@media only screen and (max-width:959px) {
     .footer-primary-container .help-stmt .scnd-col{
         width:30%;
    }
     .footer-primary-container .help-stmt .scnd-col > div> span{
         font-size:18px;
    }
     .footer-primary-container .help-stmt .scnd-col > div > span img {
         width:25px;
    }
     .addrs-outer .socio {
         width:auto;
    }
     .help-stmt .frst-col p, .addrs-outer .addr p{
         font-size:14px;
    }
     .more-links .copyright-txt p, .cat-links a, .misc-links a {
         font-size:12px;
    }
     .addrs-outer .fa-brands, .addrs-outer .fa {
         font-size:20px;
    }
}
@media only screen and (max-width:767px){
    .footer-primary.footer.container {
         background-color:#1E5D9E ;
    }
     .footer-primary-container {
         background:none;
         background-color:#1E5D9E;
    }
     .footer-primary-container .help-stmt .frst-col, .addrs-outer, .more-links {
         margin-bottom:20px;
    }
     .footer-primary-container .help-stmt .scnd-col {
         width:98%;
    }
     .addrs-outer > div, .more-links > div {
         margin-bottom:20px;
    }
     .footer-primary-container .more-links {
         padding-top:0;
         margin-bottom:0 
    }
     .footer-primary-container .more-links .cat-links {
         display:flex;
         justify-content:space-between;
    }
     .footer-primary-container .more-links .misc-links {
         display:flex;
         justify-content:space-between;
    }
     .footer-top-container .footer-top {
         padding:30px 10px;
    }
     .footer-top .stmt {
         display:flex;
         flex-wrap:wrap;
    }
     .footer-top .stmt > span:nth-child(1) {
         margin-bottom:10px;
    }
}

/*Misc*/
.page-content ul, #product-tabs .std ul, .product-shop.module-product-details ul:not(.add-to-links) {
     list-style:disc;
     margin-left:25px;
}
#header {
     position:sticky;
     top:0;
     z-index:50;
}
@media print {
     .ord-details .grid-column-wrapper {
         width:98%;
    }
     .module-trans-details-listing {
         max-width:95%;
    }
     .module-order-details-listing {
         max-width:95%;
    }
     @page {
         size:landscape 
    }
}
.isPublic .hide-for-B2C {
     display:none;
}
body:not(.isPublic) .hide-for-B2B {
     display:none;
}
input.input-text, select, textarea {
     border-color:#4D4E50;
     font-family:'DM Sans', sans-serif;
     font-size:13px;
     color:#4d4e50 !important;
     border-radius:10px;
}
input.input-text[disabled=disabled], input[disabled=disabled], input.input-text[disabled=disabled]::placeholder, input[disabled=disabled]::placeholder {
     color:#000 !important;
     -webkit-text-fill-color:rgba(0, 0, 0, 0.8);
     -webkit-opacity:0.8;
}

/*category pages*/
.sidebar-wrapper.module-category-filters .block-title span {
     text-transform:Capitalize;
     padding-left:5px;
}
.cat-filters-col .hide-below-960{
     border:1px solid #f1f2f5;
     border-radius:20px;
     margin-right:3%;
     margin-top:0 !important;
}
@media only screen and (min-width:1400px) {
     .cat-filters-col .hide-below-960{
         width:90%;
    }
}
.sidebar-wrapper.module-category-filters .block-vertnav .block-content {
     padding-bottom:0;
}
.sidebar-wrapper.module-category-filters .accordion-style1 li:last-child a {
     border-bottom:none;
}
.sidebar-wrapper.module-category-filters .accordion-style1 li a {
     color:#000;
     padding:10px;
}
/*sidebar filters*/
.sidebar-wrapper.module-category-filters .block-title {
     padding-top:0;
     border-bottom:none;
}
#filter_list_box {
     padding:0 6% 0 4%;
}
#filter_list_box dt{
     font-weight:700;
     border-top:1px solid #f1f2f5;
     padding:10px 0;
}
#filter_list_box dd{
     padding-left:0;
     margin:20px 0 10px;
}
#filter_list_box dd li{
     margin-bottom:7px;
}
#filter_list_box .fa.filter-collapsible, .fa.category-filter-collapsible {
     font-size:22px !important;
}
#filter_list_box dd ol::-webkit-scrollbar {
     width:14px;
     border:1px solid black;
     border-radius:10px;
}
#filter_list_box dd ol::-webkit-scrollbar-thumb {
     background-color:#D9D9D9;
     border:1px solid black;
     border-radius:10px;
}
.product-listing {
     margin-top:0 !important;
     margin-bottom:7% !important;
}
.category-title h1 {
     padding:0px;
}
.product-search-container, .breadcrumbs-col .module-category-misc > div, #breadcrumbs li.home span, .breadcrumbs-col .nested-container {
     margin-left:0 !important;
}
#breadcrumbs li.last-child > span {
     vertical-align:super;
}
.breadcrumb-separator {
     background:none !important;
     text-indent:0 !important;
     overflow:unset !important;
     margin:0 !important;
     font-size:16px !important;
     width:auto !important;
}
.module-category-misc .sort-by label {
     visibility:hidden;
     position:relative;
}
.module-category-misc .sort-by label:after {
     Content:'Sort:';
     text-indent:0;
     visibility:visible;
}
.toolbar-section .sort-by select {
     padding:0px;
     height:30px;
}
.sorter .view-mode .grid {
     background-image:url("https://cdn-881a96c5-a77b871b.commercebuild.com/ba4eb150ac04bbf07de87971e67be5f8/contents/ckfinder/images/grid-img.png") !important;
     background-position:3px !important;
}
.sorter .view-mode .list {
     background-image:url("https://cdn-881a96c5-a77b871b.commercebuild.com/ba4eb150ac04bbf07de87971e67be5f8/contents/ckfinder/images/list-icon-img.png") !important;
     background-position:3px !important;
}
.toolbar-section .view-mode label {
     display:none;
}
.toolbar-section .view-mode-option {
     margin-left:5px;
}
.sorter .view-mode .active, .sorter .view-mode a:hover {
     background-color:#fff !important;
}
.module-category-misc .sorter {
     height:53px;
     line-height:53px;
}
.module-category-misc .sort-by {
     height:63px;
     line-height:63px;
}
/*grid items*/
.category-products-grid .item {
     border-radius:30px;
     background-color:#f1f2f5;
     margin-bottom:50px;
     padding-bottom:85px !important;
}
.product-image-wrapper a.product-image img {
     border-radius:25px;
}
.products-grid .product-image-wrapper {
     height:auto !important;
}
.products-grid .product-image-wrapper img {
     max-width:200px;
}
.category-products-grid .item h2.product-name {
     min-height:95px;
}
.item .module-category-product-listing h2.product-name a {
     color:#000;
}
.products-grid .product-name {
     margin:15px 0;
}
.item .module-category-product-listing .product-description-wrapper .product-shop-inner {
     min-height:30px;
}
.item .module-category-product-listing .price-box span{
     font-weight:600;
}
.itemgrid-4col .item {
     width:22%;
     margin-right:1%;
}
.itemgrid-3col .item {
     width:28%;
}
.itemgrid-3col .item:nth-child(3n+2) {
     margin-left:5%;
     margin-right:5%;
}
.old-price span {
     color:#aaa !important;
}
.special-price span {
     color:#ee001c!important;
}
.product_pricetag {
     margin-right:3px;
}
.product-listing .price-box .price-label {
     font-size:16px;
}
.item .price-box .price, .item .price-box .price span{
     font-size:16px;
}
@media only screen and (min-width:768px) {
     .item .module-category-product-listing .add-cart-wrapper {
         display:grid;
         justify-content:center;
         grid-template-columns:auto auto;
    }
     .item .module-category-product-listing .add-cart-wrapper > .qty-wrapper{
         margin:auto 10px;
    }
     .item .module-category-product-listing .add-cart-wrapper > button{
         margin:auto;
         margin-left:10px;
    }
     .item .module-category-product-listing .add-cart-wrapper > ul.add-to-links {
         grid-column:span 2;
         padding-top:7px;
    }
     .module-category-product-listing .products-list .item .add-cart-wrapper {
         display:flex;
         flex-direction:row-reverse 
    }
     .module-category-product-listing .products-list .item .add-cart-wrapper > ul.add-to-links{
         max-width:150px;
    }
}
.item .module-category-product-listing .add-cart-wrapper input.qty {
     height:40px;
     border:1px solid #1e5d9e;
     border-radius:10px;
}
/*Fav icon*/
.product-listing .addto-links-icons.addto-onimage {
     float:none;
     margin-top:0;
}
 .item .module-category-product-listing .add-cart-wrapper > ul.add-to-links a {
     text-decoration:underline;
}
/*List items*/
.products-list .item-headers {
     padding:10px 0;
     background-color:#d7dbdd;
     border-top-left-radius:20px;
     border-top-right-radius:20px;
}
.products-list {
     border:1px solid #d7dbdd;
     border-radius:20px;
     border-bottom-left-radius:20px;
     border-bottom-right-radius:20px;
}
ul.products-list > li.item:last-child {
     margin-bottom:2%;
}
ul.products-list li.item {
     padding:10px 0;
}
/*Pagination*/
.toolbar-section.pagination {
     margin-top:2%;
}
.pagination .module-category-misc {
     text-align:center;
}
.pager {
     border:none;
     margin:0;
     padding:0;
}
.pager li, .pager li a, .pager strong{
     font-size:14px;
}
.pager .pages li.current, .pager li.next, .pager li.next+li, .pager li.previous, .pager li.pre-previous {
     background-color:#fff;
     border:1px solid #000;
     border-radius:30px;
}
.pager .pages li a:hover {
     background-color:#fff;
     color:#000 !important;
}
.pager li.next, .pager li.next+li, .pager li.previous, .pager li.pre-previous {
     display:flex;
     margin-right:3px;
}
.pager li.next a, .pager li.next+li a, .pager li.previous a, .pager li.pre-previous a{
     width:20px;
     height:20px;
     line-height:18px;
     margin:5px auto;
}
@media only screen and (max-width:959px) {
     .item .module-category-product-listing .grid12-12, .breadcrumbs_col .grid-column-wrapper {
         overflow-y:hidden;
    }
     .mobile-filters .fa-sliders:before {
         font-size:22px;
         font-weight:700;
    }
}
/*Hover effect*/
.category-products-grid .item:hover {
     z-index:10;
     box-shadow:0 0 20px rgba(0,0,0,.2);
     border-color:transparent;
     transition:box-shadow .4s ease-out;
     -moz-transition:box-shadow .4s ease-out;
     -webkit-transition:box-shadow .4s ease-out;
     -o-transition:box-shadow .4s ease-out;
}
/*Expand the width for small width devices*/
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
    .mh-filters-btn:after {
     content:'Filter';
     height:34px;
     display:inline-block;
     line-height:34px;
     vertical-align:top;
    }
    .mobile-filters {
         border:1px solid #000;
         border-radius:10px;
         padding:0 15px;
         height:36px;
         line-height:36px;
    }
    .cat-filters-col .grid-column-wrapper.hide-above-960 .module-category-misc {
         height:70px;
         display:flex;
         justify-content:end;
    }
    .mobile-filters .mh-head {
         line-height:40px;
    }
    .cat-filters-col {
        width:98%;
    }
    .cat-filters-col .grid-column-wrapper.hide-above-960 .module-category-misc > div {
        margin: auto 1%;
    }
}
@media only screen and (max-width:767px) {
     .products-grid .item button.button span span {
         padding:0px !important;
         height:40px !important;
         line-height:40px;
    }
     .pager .pages li {
         display:inherit;
    }
     .itemgrid.itemgrid-adaptive .item {
         width:47%;
    }
     .item .product-image img {
         max-width:120px;
    }
     .products-grid .item .add-cart-wrapper .add-to-links {
         display:block !important;
         margin-top:5px;
    }
     .module-category-misc .sort-by {
         height:36px;
         line-height:36px;
    }
    
     .mobile-filters .menu-icon.fa-sliders {
         padding-left:0 !important;
         padding-bottom:0.8em !important;
    }
    .breadcrumbs li span.breadcrumb-separator {
        height: 20px;
        line-height: 20px;
    }
    .cat-filters-col .grid-column-wrapper.hide-above-960 .module-category-misc > div {
        margin: 0 1%;
    }
    
}
#mm-filter_list_box {
     padding:6%;
}
#mm-filter_list_box dt {
     padding-top:10px;
     padding-bottom:10px;
}
#mm-filter_list_box dd ol li{
     margin-bottom:7px;
}
#mm-filter_list_box dd {
     padding-left:10%;
}
.mm-panels .sidebar-category ul.accordion, .mm-panels .sidebar-filter .block-content {
     overflow-y:scroll;
     height:300px;
}
 .mm-panels .sidebar-filter .block-content {
     height:700px;
}
/**/
.progress, div[class^=strength_meter_passwd] {
     border-radius:10px !important;
}

.sidebar.my-account-sidebar.mmenu-hide {
     margin-bottom:40px;
}
.sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu {
     display:flex;
     float:left;
     width:100%;
     background-color:#fff;
     padding-left:0 !important;
     list-style:none;
}
.sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li {
     margin:0 20px 0 0;
     border:1px solid #f1f2f5;
     border-radius:30px;
     padding:0px 20px;
     line-height:40px;
     height:40px;
}
.sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li.selected-navitem, .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover {
     background-color:#1e5d9e ;
}
.sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li.selected-navitem > a, .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover > a{
     color:#fff;
}
.sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li > a{
     padding:0;
     font-size:16px;
     color:#000;
     text-transform:capitalize;
     line-height:40px;
     height:40px;
     transition:color 0.0s ease;
}
@media only screen and (min-width:960px) and (max-width:1199px) {
    .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu>li>a {
    font-size: 12px;
    }
}
.account-view .block-content.mm-menu ul.xm-mega-menu > li {
     width:100%;
}
#order-table, #transhistory-table, #quote-table{
     margin-top:20px;
}
 #order-table .ReactTable .rt-tbody .rt-tr .rt-td:last-child a, #quote-table .ReactTable .rt-tbody .rt-tr .rt-td:last-child a{
     display:block;
     width:90px;
     text-align:center;
}
 #order-table .ReactTable .rt-tbody .rt-tr .rt-td:last-child span, #quote-table .ReactTable .rt-tbody .rt-tr .rt-td:last-child span {
     display:none;
}
.buttons-set.module-order-details-actions {
     text-align:end;
}
.buttons-set.module-order-details-actions .back-link {
     margin-bottom:1px;
}
.buttons-set.module-order-details-actions .fa-angle-double-left:before {
     content:" " !important;
}
.order-info-box h2 {
     font-size:28px !important;
}
.order-info-box .box-content {
     margin-top:25px;
}
@media only screen and (min-width:960px) {
     .progress, div[class^=strength_meter_passwd] {
         top:25px !important;
    }
     .order-info-box.module-order-details-billing, .order-info-box.module-order-details-shipping, .order-info-box.module-shipment-details-billing, .order-info-box.module-trans-details-shipping, .order-info-box.module-quote-details-billing, .order-info-box.module-order-quote-shipping{
         min-height:278px;
    }
     .col-1.addresses-primary .item, .col-2.addresses-primary .item{
         min-height:300px;
    }
     .module-order-details-misc > div {
         margin-left:5px;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .billing-module, .shipping-module, .col-1.addresses-primary, .col-2.addresses-primary {
         width:99%;
    }
     .col-2.addresses-primary {
         float:left;
    }
}
.account-view a.button span span {
     padding:0 12px;
     font-size:16px;
     font-weight:500;
     height:40px!important;
     line-height:40px!important;
     display:inline-block;
}
.account-view a.button span {
     background-color:#1e5d9e ;
     color:#fff;
     border-radius:30px;
}
.account-view a.button span span img {
     width:10px;
     margin:14px 5px;
}
.po-invc .pager .amount {
     padding-top:0;
}
.po-invc #form_filter_user_data input, .po-invc #form_filter_user_data button {
     margin-left:10px;
}
.po-invc .buttons-set p.back-link {
     display:none;
}
@media only screen and (max-width:767px) {
     .po-invc .my-account .pager p.amount, .po-invc .filter_order_search_label, .po-invc .my-account > p {
         font-size:12px !important;
    }
     .po-invc #form_filter_user_data input {
         width:100px;
    }
     .po-invc .my-account .pager p.amount {
         padding-left:5px;
         float:left;
    }
     .po-invc button.button span {
         font-size:16px;
    }
     #my-invoices-table, #my-orders-table {
         overflow:hidden;
    }
}
.quote-details ul.form-list .fieldset {
     padding-left:0;
}
/*Login section*/
.login-title, .register-title {
     margin-bottom:15px;
}
.login-section h2, .register-section h2 {
     margin-bottom:15px;
     border-bottom:1px solid #f1f2f5;
     line-height:34px;
     font-size:26px;
     padding-bottom:15px;
}
.login-section p.required {
     float:left !important;
}
.login-section .buttons-set {
     display:flex;
     flex-direction:row-reverse;
     margin-left:0;
}
.login-section .buttons-set > a{
     margin:auto;
}
.login-form-col, .reg-col-2 {
     width:45%;
}
@media only screen and (max-width:959px) {
     .login-section .grid12-6, .register-section .grid12-6{
         width:98%;
    }
     .login-form-col, .reg-col-2 {
         margin-top:25px;
    }
}
@media only screen and (max-width:767px) {
     button[title='Change Email'] {
         margin-bottom:50px !important;
    }
     .login-section h2, .register-section h2 {
         font-size:20px;
    }
}
@media only screen and (min-width:960px) {
     .login-form-col, .reg-col-2 {
         margin-left:3%;
    }
}

/*Transaction details*/
.page-title.module-trans-details-status h1 span, .page-title.module-order-details-status h1 {
     font-size:36px;
}
.module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie {
     padding:10px;
     background-color:#1e5d9e ;
     cursor:pointer;
     color:#fff;
     border-radius:30px;
     border:none;
}
.module-trans-details-actions input.form-control {
     height:30px;
     width:70px;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .xm-grid-account .grid-container-wrapper {
         overflow:unset;
    }
  .fa-bars:after {
         color:#fff !important;
    }
}
@media only screen and (max-width:959px) {
     .page-title.module-trans-details-status h1 span {
         font-size:32px !important;
    }
     .ReactTable .rt-thead.-filters input{
         max-width:80px;
    }
     .ReactTable .rt-thead.-filters select{
         width:80%;
         height:26px;
    }
     .ReactTable a, .ReactTable div, .ReactTable p, .ReactTable span{
         font-size:11px !important;
    }
}
.due-input, .module-trans-details-actions .form-control {
     font-size:16px;
}
/**/
body.cart .data-table:not(.cart-table) .col-img img {
     width:60%;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .opc-main-container.onepage-checkout-main-container, .in-sidebar.opc-progress-container {
         width:98%;
    }
}
@media only screen and (max-width:767px) {
     body.cart .data-table tfoot td {
         display:inline-block !important;
         width:30% !important;
         min-width:100px;
         max-width:140px;
         margin-right:10px;
         font-weight:700;
    }
}
.cart-action-buttons button {
     margin:20px 10px;
}
@media only screen and (max-width:767px) {
     #shopping-cart-table {
         overflow:hidden;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .cart-action-col {
         overflow:hidden;
    }
}
#shopping-cart-table h2.product-name .item-code {
     display:none;
}
@media only screen and (min-width:1200px){
     .cart-summary-col {
         width:30%;
    }
     .update-empty-cart {
         width:66%;
    }
}
/*Change the X icon to trash icon*/
.col-delete .btn-remove.btn-remove2 {
     background:none;
}
.col-delete .btn-remove.btn-remove2 .fa-trash {
     font-size:18px;
}
.tax-stmt {
     float:right;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .tax-stmt {
         font-size:14px;
    }
}
#opc-review .product-name a, #opc-review .product-name p {
     font-size:15px !important;
}
body.cart .data-table:not(.cart-table) .col-img img {
     width:80%;
}
.cart_confirm a.button span span {
     padding:0 12px;
     font-size:16px;
     font-weight:500;
     height:40px!important;
     line-height:40px!important;
     display:inline-block;
}
.cart_confirm a.button span {
     background-color:#1e5d9e ;
     color:#fff;
     border-radius:30px;
}
.cart_confirm a.button span span img {
     width:10px;
     margin:14px 5px;
}
/*Favorites popup*/
#wishlist-page {
     text-align:center;
}
#wishlist-wrapper .image img {
     width:50%;
}
#wishlist-wrapper > span {
     display:flex;
     justify-content:center;
     background-color:#1e5d9e;
     border-radius:30px;
     width:220px;
     margin:auto;
     height:40px;
     line-height:40px;
     padding:10 25px;
}
#wishlist-wrapper > span a{
     color:#fff;
     line-height:16px;
     font-size:16px;
}
#wishlist-wrapper > span img{
     margin-left:5px;
     width:10px;
     height:10px;
     margin-top:5px;
}
/*Formatting changes suggested by Anthony*/
.ReactTable .rt-thead {
     font-weight:bold;
}
.ReactTable .rt-tbody .rt-td:not(.text-right), #order-table .ReactTable .rt-tbody .rt-td:last-child div{
     text-align:center;
}
.ReactTable .rt-thead, .ReactTable .rt-tbody .rt-td, .ReactTable .rt-tbody .rt-td span, .ReactTable .rt-tbody .rt-td a, .ReactTable .rt-tbody .rt-td p {
     font-size:14px;
     line-height:14px;
}
.ReactTable {
     border :2px solid rgb(31 122 173 / 23%);
}
#order-table .ReactTable .rt-tbody .rt-td:last-child a, #quote-table .ReactTable .rt-tbody .rt-td:last-child a {
     margin:auto;
}
#address-book-table {
     margin-top:20px;
}
#user_pref_col {
     margin-bottom:20px;
}
.favourites-listing p.amount {
     padding-top:0;
}
@media only screen and (max-width:959px) {
     .fav-options > button, .fav-options .fav-remove a.button span span {
         margin-bottom:7px !important;
    }
}
.account-view a.button span span {
     height:36px !important;
     line-height:36px !important;
}
@media only screen and (max-width:767px) {
     .actionbox.faveitemaction #form_search_fav {
         display:grid;
         grid-template-columns:15% 40% auto;
         grid-gap:15px;
    }
     .actionbox.faveitemaction {
         display:grid;
    }
}
.module-trans-details-misc .item-options dt {
     margin-top:6px;
}
@media only screen and (min-width:960px) {
     .module-trans-details-misc {
         display:flex;
         justify-content:space-between;
         flex-wrap:wrap;
    }
     .module-trans-details-misc .item-options {
         display:flex;
    }
}
.module-trans-details-misc {
     padding-top:30px;
     padding-bottom:20px;
}
.module-trans-details-actions input.form-control {
     text-align:center;
}
/*Readjustment of the header logo*/
.desktop-top-header .logo-column svg {
     z-index:1000;
     position:relative;
}
@media only screen and (min-width:960px) and (max-width:1259px) {
     .desktop-top-header .logo-column svg {
         width:90%;
    }
}
@media only screen and (min-width:1260px) and (max-width:1799px) {
     .desktop-top-header .logo-column svg {
         width:75%;
         height:auto;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .desktop-top-header .logo-column {
         width:25%;
    }
}
@media only screen and (max-width:767px) {
     .mobile-header .nested-container.container {
         padding-left:1%;
         padding-right:1%;
         width:98%;
    }
     .mobile-header .grid-column-wrapper.first {
         flex-basis:50%;
    }
     .mobile-header .grid-column-wrapper.mobile-logo-img p {
         text-align:center;
    }
     .mobile-header .grid-column-wrapper.mobile-logo-img svg{
         width:75%;
    }
     .mobile-header .grid-column-wrapper.last {
         flex-basis:55%;
    }
}
